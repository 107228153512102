import { graphql } from 'gatsby';
import React from 'react';
import {
  translationsFactory,
  TranslationsContext,
  Translation,
} from 'settings/i18n';
import EducationNavigation from 'components/EducationNavigation';
import Footer from 'components/Footer';
import SEO, { SEOMeta } from 'components/SEO/SEO';
import EducationQuiz from 'components/EducationQuiz/EducationQuiz';
import ClientOnly from 'components/ClientOnly/ClientOnly';

import { QuizMachineContext } from 'components/EducationQuiz/EducationQuizMachine';

const EducationCryptocurrencyQuiz = ({
  data,
  pageContext: { url },
}: {
  data: {
    translations: Translation;
    education: Translation;
    homepage: Translation;
    common: Translation;
    metadata: SEOMeta;
  };
  pageContext: {
    url: string;
  };
}) => {
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data?.translations?.translation,
        ...data?.education?.translation,
        ...data?.common?.translation,
        ...data?.homepage?.translation,
      },
    },
  });

  const questions: QuizMachineContext['questions'] = [
    {
      id: 'qb1',
      question: 'firstQuestion',
      answers: [
        'firstQuestionFirstAnswer',
        'firstQuestionSecondAnswer',
        'firstQuestionThirdAnswer',
      ],
      correctAnswerIndex:
        Number(data.translations.translation.firstQuestionCorrectAnswerNumber) -
        1,
    },
    {
      id: 'qb2',
      question: 'secondQuestion',
      answers: [
        'secondQuestionFirstAnswer',
        'secondQuestionSecondAnswer',
        'secondQuestionThirdAnswer',
      ],
      correctAnswerIndex:
        Number(
          data.translations.translation.secondQuestionCorrectAnswerNumber,
        ) - 1,
    },
    {
      id: 'qb3',
      question: 'thirdQuestion',
      answers: [
        'thirdQuestionFirstAnswer',
        'thirdQuestionSecondAnswer',
        'thirdQuestionThirdAnswer',
      ],
      correctAnswerIndex:
        Number(data.translations.translation.thirdQuestionCorrectAnswerNumber) -
        1,
    },
    {
      id: 'qb4',
      question: 'fourthQuestion',
      answers: [
        'fourthQuestionFirstAnswer',
        'fourthQuestionSecondAnswer',
        'fourthQuestionThirdAnswer',
      ],
      correctAnswerIndex:
        Number(
          data.translations.translation.fourthQuestionCorrectAnswerNumber,
        ) - 1,
    },
    {
      id: 'qbt',
      question: 'fifthQuestion',
      answers: [
        'fifthQuestionFirstAnswer',
        'fifthQuestionSecondAnswer',
        'fifthQuestionThirdAnswer',
      ],
      correctAnswerIndex:
        Number(data.translations.translation.fifthQuestionCorrectAnswerNumber) -
        1,
    },
    {
      id: 'qb5',
      question: 'sixthQuestion',
      answers: [
        'sixthQuestionFirstAnswer',
        'sixthQuestionSecondAnswer',
        'sixthQuestionThirdAnswer',
      ],
      correctAnswerIndex:
        Number(data.translations.translation.sixthQuestionCorrectAnswerNumber) -
        1,
    },
    {
      id: 'qb6',
      question: 'seventhQuestion',
      answers: [
        'seventhQuestionFirstAnswer',
        'seventhQuestionSecondAnswer',
        'seventhQuestionThirdAnswer',
      ],
      correctAnswerIndex:
        Number(
          data.translations.translation.seventhQuestionCorrectAnswerNumber,
        ) - 1,
    },
    {
      id: 'qb7',
      question: 'eighthQuestion',
      answers: [
        'eighthQuestionFirstAnswer',
        'eighthQuestionSecondAnswer',
        'eighthQuestionThirdAnswer',
      ],
      correctAnswerIndex:
        Number(
          data.translations.translation.eighthQuestionCorrectAnswerNumber,
        ) - 1,
    },
    {
      id: 'qb8',
      question: 'ninethQuestion',
      answers: [
        'ninethQuestionFirstAnswer',
        'ninethQuestionSecondAnswer',
        'ninethQuestionThirdAnswer',
      ],
      correctAnswerIndex:
        Number(
          data.translations.translation.ninethQuestionCorrectAnswerNumber,
        ) - 1,
    },
    {
      id: 'qb10',
      question: 'tenthQuestion',
      answers: [
        'tenthQuestionFirstAnswer',
        'tenthQuestionSecondAnswer',
        'tenthQuestionThirdAnswer',
      ],
      correctAnswerIndex:
        Number(data.translations.translation.tenthQuestionCorrectAnswerNumber) -
        1,
    },
  ];

  return (
    <TranslationsContext.Provider value={t}>
      <SEO metadata={data.metadata} url={url} />
      <ClientOnly>
        <EducationNavigation />
        <EducationQuiz questions={questions} />
        <Footer />
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

export default EducationCryptocurrencyQuiz;

export const query = graphql`
  query($locale: String!, $slugRegex: String!) {
    translations: wpPage(
      slug: { regex: $slugRegex}
      language: { locale: { eq: $locale } }
    ) {
      ...QuizTranslation
    }

    common: wpPage(
      slug: { regex: "/\\b(\\w*quiz-blockchain\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...EducationQuizTranslation
    }
    
    metadata: wpPage(
      slug: { regex: $slugRegex}
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }

    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
      ...AboutTranslation
    }
  }
`;
